import './App.css';
import {Router, Route, Switch} from "react-router-dom";
import GxMaps from "./views/GxMaps";
import { history } from './jwt/history';
import GxHome from "./views/Home";

function App() {
  return (
      <Router basename="/" history={history} >
          <Switch>
              <Route exact path="/" component={GxHome} />
              <Route path="/:code/:langCode" component={GxMaps} />
          </Switch>
      </Router>
  );
}

export default App;
