import {doRequest} from "./firebaseClient";


export const langFirebaseService = {
    getList : getLangList
};

function getLangList() {
    const relativeUrl = '/configuration/langs'

    return doRequest(`${relativeUrl}.json`, 'GET')
}

export const udnFirebaseService = {
    getList : getUdnList
};

function getUdnList() {
    const relativeUrl = '/core/udn'

    return doRequest(`${relativeUrl}.json`, 'GET')
}

export const mapConfigFirebaseService = {
    getList : getMapConfigList
};

function getMapConfigList() {
    const relativeUrl = '/configuration/mapconfig'

    return doRequest(`${relativeUrl}.json`, 'GET')
}

export const activityFirebaseService = {
    getList : getActivityList
};

function getActivityList() {
    const relativeUrl = '/core/activity'

    return doRequest(`${relativeUrl}.json`, 'GET')
}

export const langActivityFirebaseService = {
    getList : getLangActivityList
};

function getLangActivityList(langCode) {
    const relativeUrl = 'lang/' + langCode + '/core/activity'

    return doRequest(`${relativeUrl}.json`, 'GET')
}

export const labelFirebaseService = {
    getList : getLabelList
};

function getLabelList(langCode) {
    const relativeUrl = 'lang/' + langCode + '/label';

    return doRequest(`${relativeUrl}.json`, 'GET')
}