import axios from "axios";
import {appEnvironment} from "../appEnv";

let environment = "";

const env = {
    develop: 'https://xkynet-dev.firebaseio.com',
    test: 'https://xkynet-dev.firebaseio.com',
    production: 'https://xkynet-main.firebaseio.com',
}

function baseEndPoint(environmentP = appEnvironment) {
    environment = environmentP.toLowerCase();
    if (environment in env) {
        return env[environment];
    } else {
        console.log("Enviroment " + environment + " it doesn't exist");
    }
}

export async function doRequest(url, method) {
    let response
    let responseData
    let api = axios.create({
        baseURL: baseEndPoint(),
        responseType: "json",
    })


    const options = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        }
    };


    try {
        response = await api(url, options)
        responseData = response?.data
    } catch(errorMessage) {
        console.log(errorMessage.response.data.message)
    }

    return responseData
}