import React, {useState, useRef, useEffect} from "react";
import { Wrapper } from "@googlemaps/react-wrapper"
import {
    PerspectiveCamera,
    Scene,
    AmbientLight,
    WebGLRenderer,
    Matrix4,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import CustomMarker from "./CustomMarker";
import {jltfAssets} from "../../assets/3d/jltf/jltfLibrary";

let mapOptions = {
    mapId: '22dc9c2af85d13f8',
    // center: { lat: 20.581465, lng: -87.120603 },
    center: { lat: 0.0, lng: 0.0 },
    zoom: 18,
    streetViewControl: false,
    disableDefaultUI: false,
    gestureHandling: "cooperative",
    heading: 0,
    tilt: 0,
};

export default function Map3D ( { markers, googleConfig, options, onMarkerClick, className = '' } ) {


    useEffect(() => {
        if (googleConfig?.mapOptions) {
            mapOptions = {
                ...googleConfig?.mapOptions,
                mapId: mapOptions.mapId
            }

            mapOptions.center = googleConfig?.mapOptions.center;

            if (googleConfig?.mapOptions.streetViewControl) {
                mapOptions.streetViewControl = googleConfig?.mapOptions.streetViewControl;
            }

            if (googleConfig?.mapOptions.disableDefaultUI) {
                mapOptions.disableDefaultUI = googleConfig?.mapOptions.disableDefaultUI;
            }
        }
    }, [ googleConfig ]);

    return (<div className={className}>
            <Wrapper apiKey={'AIzaSyA-RFluygEbx-lKv3wShH-hx_c_2F95Dbk'} version="beta" libraries={["marker"] }>
                <WebGLMap
                    markers={markers}
                    options={options}
                    onMarkerClick={onMarkerClick}
                />
            </Wrapper>
        </div>
    );
}


function WebGLMap( { markers,  options,  onMarkerClick } ) {
    const overlayRef = useRef();
    const [map, setMap] = useState();
    const ref = useRef();

    // useEffect(() => {
    //     if (!overlayRef.current) {
    //         if (mapOptions.center.lat === 0.0 && mapOptions.center.lng === 0.0) {
    //             return;
    //         }
    //         const _map = new window.google.maps.Map(ref.current, mapOptions);
    //         setMap(_map);
    //         overlayRef.current = createOverlay(_map, options);
    //         // overlayRef.current.addListener('click', onMapClick);
    //     }
    // }, [markers, options]);

    useEffect(() => {
        if (!overlayRef.current) {
            if (mapOptions.center.lat === 0.0 && mapOptions.center.lng === 0.0) {
                return;
            }
            const _map = new window.google.maps.Map(ref.current, mapOptions);
            setMap(_map);
            overlayRef.current = createOverlay(_map, options);

            // Define los límites del mapa
            const METERS = 200;
            const LATITUDE_DEGREE = METERS / 111045;  // Aproximadamente 111,045 metros en un grado de latitud
            const LONGITUDE_DEGREE = METERS / 111320; // Aproximadamente 111,320 metros en un grado de longitud en el ecuador

            const bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(mapOptions?.bound?.sw?.lat - LATITUDE_DEGREE, mapOptions?.bound?.sw?.lng - LONGITUDE_DEGREE), // Esquina suroeste
                new window.google.maps.LatLng(mapOptions?.bound?.ne?.lat + LATITUDE_DEGREE, mapOptions?.bound?.ne?.lng + LONGITUDE_DEGREE) // Esquina noreste
            );
            // _map.fitBounds(bounds);
            _map.setRestriction({
                latLngBounds: bounds,
                strictBounds: true,
            });
        }
    }, [markers, options]);

    return (<>
        <div ref={ref} id="map" key={'map'} className={'map3d_container'}/>
        {map && markers && <CreateMarkers map={map} markers={markers} onMarkerClick={onMarkerClick} showMarker={options.showActivities} />}
    </>);
}


function createOverlay(map, options) {
    const overlay = new window.google.maps.WebGLOverlayView();
    let renderer, scene, camera, loader;

    overlay.onAdd = () => {
        scene = new Scene();
        camera = new PerspectiveCamera();
        const light = new AmbientLight(0xffffff, 0.9);
        scene.add(light);

        loader = new GLTFLoader();

        if (options?.show3D) {
            const jltfAsset = jltfAssets[options?.udn?.code?.toLowerCase()];

            loader.loadAsync(jltfAsset.asset).then((object) => {
                const group = object.scene;
                group.scale.setScalar(jltfAsset.scalar);
                group.rotation.set(Math.PI / 2, 0, 0);

                if (jltfAsset.position.x) {
                    group.position.setX(jltfAsset.position.x);
                }

                if (jltfAsset.position.y) {
                    group.position.setY(jltfAsset.position.y);
                }

                if (jltfAsset.position.z) {
                    group.position.setZ(jltfAsset.position.z);
                }

                scene.add(group);
            });
        }

    };

    overlay.onContextRestored = ({ gl }) => {
        renderer = new WebGLRenderer({
            canvas: gl.canvas,
            context: gl,
            ...gl.getContextAttributes(),
        });
        renderer.autoClear = false;

        loader.manager.onLoad = () => {
            renderer.setAnimationLoop(() => {
                renderer.setAnimationLoop(null);
            });
        };
    };

    overlay.onDraw = ({ transformer }) => {
        const matrix = transformer.fromLatLngAltitude({
            lat: mapOptions.center.lat,
            lng: mapOptions.center.lng,
            altitude: 120,
        });
        camera.projectionMatrix = new Matrix4().fromArray(matrix);

        overlay.requestRedraw();
        renderer.render(scene, camera);
        renderer.resetState();
    };

    overlay.setMap(map);

    return overlay;
}

function CreateMarkers ({ map, markers, onMarkerClick, showMarker }) {
    const [_markers, setMarkers] = useState([]);

    useEffect(() => {
        setMarkers(markers);
    }, [markers]);

    const onThisClick = (_marker) => {
        const result = _markers.map((m) => {
            if (m.id === _marker.id) {
                return {
                    ...m,
                    selected: !m.selected
                }
            }
            return {
                ...m,
                selected: false
            }
        });

        setMarkers(result);
        onMarkerClick(_marker);
    }

    return (<>
            {
                _markers && _markers.map((_marker) => {
                    return (<>
                        <CustomMarker
                            key={_marker.code}
                            map={map}
                            position={_marker.position}
                            showMarker={showMarker }
                            onClick={() => onThisClick(_marker)}
                            selected={_marker.selected} // Pasa el estado 'selected' a 'CustomMarker'
                        >
                            <div className={'activity-marker' + (_marker.selected ? '-selected' : '')}>
                                <div className={'activity-marker-number'}>{_marker.code}</div>
                                <div className={'activity-marker-name'}>{_marker.name}</div>
                                { _marker.includedLabel && <div className={'activity-marker-included'}>{_marker.includedLabel}</div> }
                                { _marker.routeName && <div className={'activity-marker-route'}>{_marker.routeName}</div> }
                                { _marker.categoryName && <div className={'activity-marker-category'}>{_marker.categoryName}</div>}
                            </div>
                        </CustomMarker>
                    </>);
                })
            }
        </>
    );
}
