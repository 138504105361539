import { useEffect, useRef} from "react";
import { createRoot } from "react-dom/client"

export default function CustomMarker ( { map, children, position, showMarker, onClick, selected } ) {
    const markerRef = useRef();
    const rootRef = useRef();

    useEffect(() => {
        if (!rootRef.current) {
            if ( Number.isNaN(position?.lat) || Number.isNaN(position?.lng) ){
                return
            }

            const container = document.createElement("div");
            rootRef.current = createRoot(container);
            markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
                position : position
                , content : container
                , zIndex : selected ? 1 : 0
            });
        }
    }, []);

    useEffect(() => {
        if ( Number.isNaN(position?.lat) || Number.isNaN(position?.lng) ){
            return
        }

        rootRef.current.render(children);
        markerRef.current.position = position;
        markerRef.current.zIndex = selected ? 1 : 0;
        markerRef.current.map = showMarker ? map : null;
        const listener = markerRef.current.addListener('click', onClick);
    }, [map, children, position]);

    // Agrega o quita la clase 'marker-hidden' dependiendo del estado de 'selected'
    useEffect(() => {
        if (selected) {
            markerRef.current.content.classList.remove('marker-hidden');
        } else {
            markerRef.current.content.classList.add('marker-hidden');
        }
    }, [selected]);
}