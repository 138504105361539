import React, {useEffect, useState} from 'react';
import gpoImage from "../assets/icon/gpoxc-icon.svg";
import {  labelFirebaseService, udnFirebaseService } from "../jwt/firebaseGenericService";
import {history} from "../jwt/history";

const GxHome = (props) => {

    const udn = { code : (props?.match?.params?.code || 'xc') }
    const lang = { id : (props?.match?.params?.langCode || 'es') }

    const [udnOptions, setUdnOptions] = useState( [] );

    const [labels, setLabels] = useState([]);

    useEffect(() => {
        getLabels(lang.id);
    }, [lang.id]);

    useEffect(() => {
        if (labels.length > 0 && udn.code) {
            getUdns();
        }
    }, [labels, udn.code]);

    const getUdns = () => {
        let _udns = [];

        udnFirebaseService.getList()
            .then((response) => {
                Object.entries(response).map(([_, value]) => (
                    _udns.push(value)
                ))

                setUdnOptions(_udns);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getLabels = (_langCode) => {
        let _labels = [];

        labelFirebaseService.getList(_langCode)
            .then((response) => {
                Object.entries(response).map(([key, value]) => (
                    _labels.push({
                        code: key,
                        name: value?.value
                    })
                ))

                setLabels(_labels);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const redirect = (udnCode, _langCode) => {
        history.push("/" + udnCode + '/' + _langCode);
        window.location.reload();
    }

    const getLabel = ( labelCode ) => {
        let _label = '';

        labels.map((label) => {
            if (label.code.toLowerCase() === labelCode.toLowerCase()) {
                _label = label.name;
            }
            return _label;
        });

        return _label;
    }

    return (
        <div >
            <div className={'gxmap-header'}>
                <div className="wrap-header">
                    <img className={'gxmap-header-icon'} src={gpoImage} alt={''}/>
                    {
                        udnOptions && udnOptions.length > 0 &&
                        udnOptions.map((_udn, index) => {
                            return (
                                <button key={index} className={'gxmap-header-udn'}
                                        onClick={_ => redirect(_udn.code.toLowerCase(), lang.id)}>{getLabel('lbl_map_prefix_' + _udn.code.toLowerCase())} </button>

                            )
                        })
                    }
                    <div className="float-right">
                        <button key={'langs'} className={'gxmap-header-lang'}
                                onClick={_ => {
                                    redirect(udn.code.toLowerCase(), (lang?.id === 'es' ? 'en' : 'es'))
                                }}>
                            <b>
                                {lang?.id.toUpperCase()}
                            </b>
                        </button>

                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1>{ getLabel('lbl_select_park') }</h1>
        </div>
    );
}

export default GxHome;

