import React, {useEffect, useState} from 'react';
import gpoImage from "../assets/icon/gpoxc-icon.svg";
import {
    activityFirebaseService, labelFirebaseService,
    langActivityFirebaseService,
    mapConfigFirebaseService,
    udnFirebaseService
} from "../jwt/firebaseGenericService";
import Map3D from "./Map3D/Map3D";
import {history} from "../jwt/history";
import SmartBanner from "react-smartbanner";

const GxMaps = (props) => {

    const udn = { code : props?.match?.params?.code  }
    const lang = { id : props?.match?.params?.langCode }

    const [googleConfig, setGoogleConfig] = useState( null);
    const [udnOptions, setUdnOptions] = useState( [] );

    const [labels, setLabels] = useState([]);
    const [markers, setMarkers] = useState([]);

    const [banner, setBanner] = useState( false );
    const [userPosition, setUserPosition] = useState( null );

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);
    const isMacOS = /Macintosh/.test(navigator.userAgent);
    const isWindows = /Windows/.test(navigator.userAgent);

    let forceOS = null;
    if (isIOS) {
        forceOS = "ios";
    } else if (isAndroid) {
        forceOS = "android";
    } else if (isMacOS) {
        forceOS = "ios";
    } else if (isWindows) {
        forceOS = "android";
    }

    // useEffect(() => {
    //     if (userPosition && googleConfig?.mapOptions?.bound) {
    //         if (!getUserIsInBounds(userPosition, googleConfig?.mapOptions?.bound)) {
    //             setBanner(false);
    //         } else {
    //             setBanner(true);
    //         }
    //     }
    // }, [userPosition, googleConfig]);

    useEffect(() => {
        getLabels(props?.match?.params?.langCode);
    }, []);

    useEffect(() => {
        if (labels.length > 0) {
            getUdns();
            getMapConfig(props?.match?.params?.code);
            getActivities(props?.match?.params?.code, props?.match?.params?.langCode);
        }
    }, [labels]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((navigatorPosition) => {
            // console.log( 'lat:' +  navigatorPosition.coords.latitude,  'lng:' + navigatorPosition.coords.longitude)
            setUserPosition({
                lat : parseFloat(navigatorPosition.coords.latitude) ,
                lng : parseFloat(navigatorPosition.coords.longitude),
            });
        })
    }, []);

    const getUdns = () => {
        let _udns = [];

        udnFirebaseService.getList()
            .then((response) => {
                Object.entries(response).map(([_, value]) => (
                    _udns.push(value)
                ))

                setUdnOptions(_udns);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getLabels = (langCode) => {
        let _labels = [];

        labelFirebaseService.getList(langCode)
            .then((response) => {
                Object.entries(response).map(([key, value]) => (
                    _labels.push({
                        code: key,
                        name: value?.value
                    })
                ))

                setLabels(_labels);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getMapConfig = (udnCode) => {
        mapConfigFirebaseService.getList()
            .then((response) => {
                Object.entries(response).map(([key, value]) => {
                    if (value?.udnCode.toLowerCase() === udnCode.toLowerCase()) {
                        setGoogleConfig({
                            mapOptions: {
                                center: { lat: value?.latitude, lng: value?.longitude },
                                disableDefaultUI: false,
                                mapTypeControl: false,
                                rotateControl: false,
                                streetViewControl: false,
                                zoom: value?.defaultZoom,
                                minZoom: value?.minZoom ,
                                maxZoom: value?.maxZoom + 1,

                                bound : {
                                    sw: {
                                        lat: value?.bound2Lat,
                                        lng: value?.bound2Long
                                    },
                                    ne: {
                                        lat: value?.bound1Lat,
                                        lng: value?.bound1Long
                                    }
                                },


                                gestureHandling: "cooperative",
                                heading: 0,
                                tilt: 0,
                            }
                        })
                    }
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getActivities = (udnCode, langCode) => {
        activityFirebaseService.getList()
            .then((response) => {
                langActivityFirebaseService.getList(langCode)
                    .then((langResponse) => {

                        let activities = [];
                        // Filtra las actividades que pertenecen a udnCode
                        Object.entries(response).map(([key, value]) => {
                            if (!value?.status && value?.status === 0)
                                return;

                            if (value?.udnCode.toLowerCase() === udnCode.toLowerCase()) {
                                let langValue = langResponse[key];
                                let _activity = {...value};

                                _activity.id = key;

                                if(langValue){
                                    _activity.name = langValue?.name;
                                    _activity.routeName = langValue?.routeName;
                                    _activity.categoryName = langValue?.categoryName;
                                }

                                activities.push(_activity);
                            }
                        })

                        setMarkers( mapActivitiesToMarkers(activities) );
                    })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const mapActivitiesToMarkers = (activities) => {
        let markers = [];

        activities.map((_activity) => {
            const _marker = {
                id: _activity.id,

                name: _activity.name,
                code: _activity.number,
                number: _activity.number,
                routeName: _activity.routeName,
                categoryName: _activity.categoryName,
                includedLabel : getLabel('lbl_map_activity_' + (_activity.aditionalCost === 0 ? 'included' : 'not_included')),

                position: {
                    lat: parseFloat(_activity.lat),
                    lng: parseFloat(_activity.lng)
                }
            }

            markers.push( _marker );
        });

        return markers;
    }


    const notImplemented = () => {
    }

    const redirect = (udnCode, langCode) => {
        history.push("/" + udnCode + '/' + langCode);
        window.location.reload();
    }

    const getLabel = ( labelCode ) => {
        let _label = '';

        labels.map((label) => {
            if (label.code.toLowerCase() === labelCode.toLowerCase()) {
                _label = label.name;
            }
        });

        return _label;
    }

    const getUserIsInBounds = (userPosition, bounds) => {
        if (userPosition && bounds) {
            return bounds.sw.lat <= userPosition.lat && userPosition.lat <= bounds.ne.lat &&
                bounds.sw.lng <= userPosition.lng && userPosition.lng <= bounds.ne.lng;
        }
        return false;
    }

    return (
        <div >
            <div className={'gxmap-header'}>
                <div className="wrap-header">
                    <img className={'gxmap-header-icon'} src={gpoImage} alt={''} />
                    {
                        udnOptions && udnOptions.length > 0 &&
                        udnOptions.map((_udn, index) => {
                            return (
                                <button key={index} className={'gxmap-header-udn'} onClick={_ => redirect(_udn.code.toLowerCase(), lang.id)}>{ getLabel( 'lbl_map_prefix_' + _udn.code.toLowerCase()) } </button>

                            )
                        })
                    }
                    <div className="float-right">
                        <button key={'langs'} className={'gxmap-header-lang'}
                                onClick={_ => {
                                    redirect(udn.code.toLowerCase(), (lang?.id === 'es' ? 'en' : 'es'))
                                }}>
                            <b>
                                {props?.match?.params?.langCode.toUpperCase()}
                            </b>
                        </button>

                    </div>
                </div>
            </div>
            {
                googleConfig &&
                <Map3D
                    className="gx-map-container"
                    markers={markers}
                    googleConfig={googleConfig}
                    options={{
                        showRouteMarkers: false,
                        showRoutes: false, showEasyPath: false, show3D: true, udn: udn, showActivities: true}}
                    onMarkerClick={notImplemented}
                />
            }
            {
                banner &&
                <SmartBanner
                    title="App oficial de GRUPO XCARET!"
                    author="XCARET!"
                    speedIn={300}
                    speedOut={400}
                    useIcon={true}
                    icon={'../assets/smartbanner/apple-touch-icon.png'}
                    iconOverwrite={'../assets/smartbanner/apple-touch-icon.png'}
                    iconGloss={'null'}
                    showFree={true}
                    daysHidden={0}
                    daysReminder={0}
                    popupDuration={6000}
                    popupSpeedIn={200}
                    popupSpeedOut={900}
                    theme={'auto'}
                    position="top"
                    force={forceOS}
                />
            }
        </div>
    );
}

export default GxMaps;

