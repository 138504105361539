import asset_3d_park_xc from "./xcaret_park_cloud.01.gltf";
import asset_3d_park_xh from "./xel_ha_park_cloud.gltf";

export const jltfAssets = {
    "xc": {
        asset: asset_3d_park_xc,
        scalar: 27.5,
        position: { x: -69, y: +98, z: -120 }
    },
    "xh": {
        asset: asset_3d_park_xh,
        scalar: 25.5,
        position: { x: -60, y: -78, z: -120 }
    }
}